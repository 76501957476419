import { get } from './http'

export const videoDistribution = (params) => {
  const url = '/analyze/video/distribution'
  return get(url, params)
}

export const videoTimes = (params) => {
  const url = '/analyze/video/times'
  return get(url, params)
}

export const questionDistribution = (params) => {
  const url = '/analyze/question/distribution'
  return get(url, params)
}

export const questionTimes = (params) => {
  const url = '/analyze/question/times'
  return get(url, params)
}

export const questionWrong = (params) => {
  const url = '/analyze/question/wrongs'
  return get(url, params)
}
